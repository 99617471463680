import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  p {
    font-size: 24px;
    line-height: 30px;

    @media ${device.tablet} {
      font-size: 20px;
      line-height: 25px;
    }
    @media ${device.desktopLG} {
      font-size: 24px;
      line-height: 30px;
    }
    @media ${device.desktopXL} {
      font-size: 32px;
      line-height: 40px;
    }
  }
`
