import React from 'react'
import Img from 'gatsby-image'

import SectionComponent from 'src/components/SectionComponent'
import usePageQuery from '../../pageQuery'
import { Container } from './styles'

const QuandoEstouNoPalco = () => {
  const data = usePageQuery()

  return (
    <SectionComponent
      id='quando-estou-no-palco'
      SectionStyles='py-5 bg-orange--extra align-items-md-center'
      minHeight={{ sm: '734px', md: '416px', lg: '504px', xl: '648px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 align-items-xl-center d-xl-flex flex-xl-column'>
        <Img fluid={data.QuandoEstouNoPalco.fluid} alt='Imagem de Thiaguinho em um show' />
        <span className='fs-12 lh-15 text-white text-center d-block mt-4'>Imagem: Reprodução/Instagram/Divulgação</span>
      </div>
      <Container className='col-12 col-md-6 col-xl-5 pl-lg-0'>
        <p className='font-sora text-white fw-600'>
          <span className='d-lg-block'>"Quando estou neste palco tenho um sentimento diferente.</span>
          <span className='d-lg-block'>Tem algo ali que toca as pessoas de forma mais profunda.</span>
          Acredito que seja a prova de que a música tem um poder de conexão"
        </p>
        <span className='text-grayscale--500 fs-16 lh-20'>Thiaguinho</span>
      </Container>
    </SectionComponent>
  )
}

export default QuandoEstouNoPalco
