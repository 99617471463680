import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import ConcorraApremios from './sections/concorra-a-premios/_index'
import QuandoEstouNoPalco from './sections/quando-estou-no-palco/_index'
import QuemSomos from './sections/quem-somos/_index'
import SigaInter from './sections/siga-o-inter/_index'

import pageContext from './pageContext.json'

const VolteParaInter = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <ConcorraApremios />
      <QuandoEstouNoPalco />
      <QuemSomos />
      <SigaInter />
    </Layout>
  )
}

export default VolteParaInter
