import React from 'react'
import SectionComponent from 'src/components/SectionComponent'

import ConcorraApremiosJson from '../../assets/data/concorra-a-premios.json'

import { ContainerPromotion, CardPosition, ButtonPosition } from './styles'

type CardPositionProps = {
  position: string;
  title: string;
  description: string;
}

const ConcorraApremios = () => {
  return (
    <SectionComponent
      id='concorra-a-premios'
      SectionStyles='py-5'
      minHeight={{ sm: '986px', md: '554px', lg: '622px', xl: '661px' }}
    >
      <ContainerPromotion className='col-12 text-left'>
        <div className='col-12 col-md-8 mx-md-auto text-md-center '>
          <h2 className='text-grayscale--500 font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50'>
            Concorra a prêmios e aproveite a Tardezinha com o Inter&Co
          </h2>
        </div>
        <div className='d-md-flex justify-content-md-center'>
          {ConcorraApremiosJson.map((item: CardPositionProps) => (
            <CardPosition key={item.title} className='text-center'>
              <ButtonPosition>
                <span className='font-sora text-white fs-16 lh-20 fs-md-24 lh-md-30'>
                  {item.position}
                </span>
              </ButtonPosition>
              <h3 className='text-grayscale--500 fs-16 lh-20 fs-lg-20 lh-lg-25 mt-4'>
                {item.title}
              </h3>
              <p
                className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-19'
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </CardPosition>
          ))}
        </div>
        <div className='col-12 col-md-8 col-lg-10 mt-4 mt-md-5 pt-md-3 text-center mx-md-auto'>
          <p className='text-orange--extra fs-12 lh-15 fs-md-14 lh-md-18 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 mb-0'>
            O sorteio será realizado no dia 23 de Junho, sexta feira, as 5PM - Miami Time Zone (GMT -4).
            Fique de olho no seu e-mail e telefone, é por lá que o nosso time vai entrar em contato! Boa sorte!
          </p>
        </div>
      </ContainerPromotion>
    </SectionComponent>
  )
}

export default ConcorraApremios
