import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SectionComponent from 'src/components/SectionComponent'
import InterCo from './../../assets/images/inter-co.svg'

import usePageQuery from '../../pageQuery'
import { Container } from './styles'
import { WIDTH_MD } from 'src/utils/breakpoints'

const NosSomos = () => {
  const width = useWidth()
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='quando-estou-no-palco'
      SectionStyles='py-5 bg-grayscale--100'
      minHeight={{ sm: '809px', md: '565px', lg: '606px', xl: '771px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 order-md-last'>
        <Img fluid={data.QuemSomosInter.fluid} alt='Fachada do prédio do Inter em Belo Horizonte' className='float-lg-right' />
      </div>
      <Container className='col-12 col-md-6 col-xl-5 pl-lg-0'>
        <h2 className='text-grayscale--500 fs-24 lh-30'>Olá, nós somos</h2>
        <img src={InterCo} width={ width <= WIDTH_MD ? 126.02 : 144.02} height={width <= WIDTH_MD ? 28 : 32} className='mb-3' />
        <p className='text-grayscale--500'>
          Nascemos pra simplificar a vida e tirar as burocracias do caminho pra você focar no que realmente importa.
        </p>
        <p className='text-grayscale--500'>
          Hoje, estamos presentes em <strong>99%</strong> dos municípios brasileiros e simplificamos
          a vida de mais de <strong>25 milhões</strong> de usuários.
        </p>
        <p className='text-grayscale--500'>
          Depois de revolucionar a vida financeira no Brasil, chegamos aos Estados Unidos.
        </p>
        <p className='text-grayscale--500'>
          Agora, você conta com o aplicativo Inter Global pra fazer suas compras e remessas, além de viver experiências únicas.
        </p>
        <p className='text-grayscale--500'>
          Estamos lado a lado com o Thiaguinho e a Tardezinha, afinal, viver seus eventos preferidos com o Inter também <strong className='text-orange--extra'>#PodeSerSimples</strong>.
        </p>
        <Link
          to='/pra-voce/global-account/'
          title='Conheça Inter global'
          className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4 mt-md-3'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_05',
              section_name: 'Olá, nós somos  Inter&Co',
              element_action: 'click button',
              element_name: 'Conheça Inter global',
              redirect_url: window.location.href + '/pra-voce/global-account/',
            })
          }}
        >
          Conheça Inter global
        </Link>
      </Container>
    </SectionComponent>
  )
}

export default NosSomos
