import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`

 .gatsby-image-wrapper {

  @media ${device.tablet} {
    width: 135px;
    height: 108px;
  }
  @media ${device.desktopLG} {
    width: 165px;
    height: 132px;
  }
  @media ${device.desktopXXL} {
    width: 205px;
    height: 164px;
  }
 }
`
