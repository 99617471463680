import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SectionComponent from 'src/components/SectionComponent'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import SigaOinter from '../../assets/data/siga-o-inter.json'

import { ContainerPromotion, CardPosition } from './styles'

type CardPositionProps = {
  icon: string;
  title: string;
  subTitle: string;
  url: string;
}

const SigaInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='siga-o-inter'
      SectionStyles='py-5'
      ContainerStyles='mx-lg-auto'
      minHeight={{ sm: '456px', md: '276px', lg: '306px', xl: '306px' }}
    >
      <ContainerPromotion className='col-12'>
        {SigaOinter.map((item: CardPositionProps) => (
          <CardPosition key={item.title} className='d-flex align-items-center'>
            <div className='mr-3'>
              <OrangeIcon size='MD' color='#161616' icon={item.icon} />
            </div>
            <div>
              <span className='font-sora d-block fw-600 text-grayscale--500 fs-18 lh-23 mb-2'>
                {item.title}
              </span>
              <span className='text-grayscale--400'>
                <a
                  href={item.url} title={item.url}
                  className='d-block fs-14 lh-17'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      section_name: 'Links promocionais',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.url,
                    })
                  }}
                >
                  {item.subTitle}
                </a>
              </span>
            </div>
          </CardPosition>
          ))}
      </ContainerPromotion>
    </SectionComponent>
  )
}

export default SigaInter
