import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const ContainerPromotion = styled.div`
  position: relative;
`

export const CardPosition = styled.div`
  background: ${white};
  width: 100%;
  height: 80px;
  border-radius: 8px;
  padding: 16px 10px 16px 17px;
  margin-bottom: 16px;
  border: 1px solid ${grayscale[200]};

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    width: 336px;
    height: 78px;
    margin-right: 24px;
    float: left;

    &:last-child {
      margin-right: 0;
    }
    :nth-last-child(2n) {
      margin-bottom: 0;
    }
    :nth-last-child(3n) {
      margin-right: 0;
    }
  }
  @media ${device.desktopLG} {
    width: 456px;
  }
  @media ${device.desktopXXL} {
    width: 548px;
  }
  @media ${device.desktopXXXL} {
    width: 690px;
  }

  a {
    color: ${grayscale[400]}
  }
`
