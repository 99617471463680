import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { Container } from './styles'
import { WIDTH_MD } from 'src/utils/breakpoints'

const Hero = () => {
  const data = usePageQuery()
  const width = useWidth()

  return (
    <SectionComponent
      id='tardezinha'
      SectionStyles='py-5 bg-grayscale--100 align-items-center'
      minHeight={{ sm: '591px', md: '416px', lg: '504px', xl: '648px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
        <Img fluid={data.ThiaguinhoHero.fluid} alt='Imagem de Thiaguinho em um show' />
      </div>
      <Container className='col-12 col-md-6 col-xl-5 pr-md-0 pl-lg-0 pr-xl-4'>
        {width >= WIDTH_MD &&
          <div className='col-12 px-0 mb-md-4'>
            <Img fluid={data.LogoTardezinhaMiami.fluid} alt='Logo Tardezinha Thiaguinho' />
          </div>
        }
        <h1 className='text-orange--extra fw-600 mb-4 font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-36 lh-xl-45'>
          Concorra a prêmios e aproveite a Tardezinha com Inter&Co
        </h1>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 mb-0'>
          Depois de 162 edições e 6 anos de sucesso a Tardezinha chega aos Estados Unidos. Thiaguinho vai
          comandar a festa em Miami criando momentos únicos ao lado dos fãs.
        </p>
      </Container>
    </SectionComponent>
  )
}

export default Hero
