import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

export const ContainerPromotion = styled.div`
   h2 {
    margin-bottom: 50px;

    @media ${device.tablet} {
      margin-bottom: 84px;
    }
  }
`

export const CardPosition = styled.div`
  background: ${grayscale[100]};
  width: 100%;
  height: 226px;
  border-radius: 12px;
  padding: 21px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    width: 216px;
    margin-right: 24px;
    height: 322px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
  @media ${device.desktopLG} {
    width: 296px;
  }
  @media ${device.desktopXXL} {
    width: 357px;
    height: 277px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const ButtonPosition = styled.div`
  background: ${orange.extra};
  width: 100px;
  height: 44px;
  border-radius: 12px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  align-items: center;
  display: flex;
  position: relative;
  margin: -45px auto 0 auto;
  left: 0;
  right: 0;
  justify-content: center;

  @media ${device.tablet} {
    width: 135px;
    height: 54px;
  }
`
