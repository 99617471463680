import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  p {
    font-size: 14px;
    line-height: 17px;

    @media ${device.desktopLG} {
      font-size: 16px;
      line-height: 19px;
    }
    @media ${device.desktopXXL} {
      font-size: 18px;
      line-height: 22px;
    }
  }
`
